import * as S from "./Footer.style.js";
import sourcewiz from "../../static/assets/sourcewiz.png";
import { useEffect, useState } from "react";

const links = [
  {
    title: "Home",
    link: "/#home",
  },
  {
    title: "Products",
    link: "/#products",
  },
  {
    title: "About us",
    link: "/#about",
  },
  {
    title: "Contact us",
    link: "/#contact",
  },
];

const Footer = ({ logo, companyName, prodArray, brandArray }) => {
  let [currentYear, setCurrentYear] = useState(0);

  useEffect(() => {
    let date = new Date().getFullYear();
    setCurrentYear(date);
  }, []);

  return (
    <>
      <S.Container>
        <S.Content>
          <S.Box>
            <S.CompanyCred>
              <S.Content>
                <S.Image src={logo} />
                <S.Header> {companyName}</S.Header>
              </S.Content>
              <S.Content>
                <div style={{ margin: "0.6em 3em 0.6em 0" }}>
                  Powered by{" "}
                  <a href="https://sourcewiz.co/" target="blank">
                    <S.Image src={sourcewiz} style={{ margin: "auto 0.5em" }} />
                  </a>
                </div>
              </S.Content>
            </S.CompanyCred>
          </S.Box>

          <S.Box>
            <S.BoldText>Products</S.BoldText>
            {prodArray.map((value, key) => (
              <S.ListText href={value.link} target="blank">
                {value.title}
              </S.ListText>
            ))}
          </S.Box>

          {/* <S.Box>
                    <S.BoldText>Brands we work with</S.BoldText>
                    <S.Content>
                        <S.BrandBox>{brandArray.map((value, key) => <S.ListText href={value.link} target="blank">{value.title}</S.ListText>)}</S.BrandBox>
                    </S.Content>
                </S.Box> */}

          <S.Box>
            <S.BoldText>Quick links</S.BoldText>
            {links.map((value, key) => (
              <S.ListText href={value.link}>{value.title}</S.ListText>
            ))}
          </S.Box>
        </S.Content>
      </S.Container>
      <div
        style={{
          color: "#B4B7B6",
          width: "100vw",
          textAlign: "center",
          background: "#1D2624",
          color: "white",
          height: "25px",
          padding: "3em 0",
          fontSize: "14px",
        }}
      >
        Copyright @{companyName} {currentYear - 1}-{currentYear}
      </div>
    </>
  );
};

export default Footer;
