import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "100vw",
  backgroundRepeat: "no-repeat",
  zIndex: -1,
  maxWidth: "1440px",
  padding: "1.5em 0 0 0em",
  "@media only screen and (max-width : 1000px)": {},
});

export const Box = styled("div", {
  display: "flex",
  // width: "80vw",
  margin: "2rem 0",
  maxWidth: "inherit",
  gap: "18em",
  "@media only screen and (max-width : 1000px)": {
    flexDirection: "column",
    alignItems: "center",
  },
  "@media only screen and (max-width : 600px)": {
    width: "90vw",
    gap: "0",
  },
});
export const Content = styled("div", {
  width: "50%",
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  objectFit: "cover",
  maxWidth: "500px",
  "@media only screen and (max-width : 1000px)": {
    width: "100%",
    alignItems: "flex-start",
    textAlign: "justify",
  },
});

export const Image = styled("img", {
  width: "95%",
  "@media only screen and (max-width : 600px)": {
    width: "100%",
  },
});

export const Heading = styled("h1", {
  fontFamily: "Raleway, sans-serif",
});

export const Para = styled("p", {
  fontFamily: "DM Sans",
  lineHeight: "32px",
  margin: "2rem 0",
});
