import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "row",
  width: "auto",
  backgroundRepeat: "no-repeat",
  // zIndex: -,
  margin: "2em 0",
  "@media only screen and (max-width : 750px)": {
    textAlign: "justify",
    padding: "1em 0em",
    flexDirection: "column",
  },
});

export const Box = styled("div", {
  display: "flex",
  margin: "10em 2em",
  width: "50%",
  flexDirection: "column",
  "@media only screen and (max-width : 750px)": {
    padding: "2em 2em 0 2em",
    margin: "8em 2em 2em 2em",
    width: "100%",
    alignItems: "flex-start",
    margin: "2em 0",
  },
});

export const Header = styled("h1", {
  paddingTop: "0",
  color: "rgba(0, 0, 0, 1)",
  fontFamily: "Raleway, sans-serif",
  "@media only screen and (max-width : 750px)": {
    marginTop: "0em",
  },
});

export const Text = styled("p", {
  padding: "2em 10em 2em 0",
  color: "rgba(29, 38, 36, 1)",
  lineHeight: "32px",
  fontFamily: "DM Sans",
  "@media only screen and (max-width : 750px)": {
    textAlign: "justify",
    padding: "1em 0em",
  },
});

export const Image = styled("img", {
  padding: "1em 4em",
  //   width: "80vw",
  "@media only screen and (max-width : 750px)": {
    width: "100%",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexDirection: "row",
  padding: "5em 0 0 0",
  width: "50%",
  margin: "0 auto",
  "@media only screen and (max-width : 750px)": {
    flexDirection: "column",
    width: "100% !important",
    padding: "2em 1em",
  },
});

export const Video = styled("video", {});
