import Logo from "./assets/logo.png";
import Background from "./assets/Hero_Background.png";
import Hero_Foreground from "./assets/Hero_Foreground.png";
import Product_1 from "./assets/Product_1.png";
import Product_2 from "./assets/Product_2.png";
import Product_3 from "./assets/Product_3.png";
import Product_4 from "./assets/Product_4.png";

import About_1_Background from "./assets/About_1_Background.png";
import About_1_Foreground from "./assets/About_1_Foreground.png";
import Description_1_Background from "./assets/Description_1_Background.png";
import Description_1_Foreground from "./assets/Description_1_Foreground.png";
import Sustainability_1_Background from "./assets/Sustainability_1_Background.png";
import Sustainability_1_Foreground from "./assets/Sustainability_1_Foreground.png";
import Artisan_Foreground from "./assets/Artisans_foreground.png";
import Artisan_Background from "./assets/Artisans_background.png";

import Product_6 from "./assets/Product_6.png";
import Product_7 from "./assets/Product_7.png";
import Product_8 from "./assets/Product_8.png";
import Product_9 from "./assets/Product_9.png";
import Product_10 from "./assets/Product_10.png";

import Certification_1 from "./assets/Certifications_1.png";
import Certification_2 from "./assets/Certifications_2.png";
import Certification_3 from "./assets/Certifications_3.png";
import Certification_4 from "./assets/Certifications_4.png";
import Certification_5 from "./assets/Certifications_5.png";
import Certification_6 from "./assets/Certifications_6.png";

import About_1 from "./assets/About_1.png";
// import About_2 from "./assets/About_2.png";
// import About_3 from "./assets/About_3.png";

import artisans from "./assets/artisans.png";
import sustainability from "./assets/sustainability.png";

const data = {
  companyName: "Bansal Carpets",
  mainText: "Weaving threads of comfort",
  subText:
    "Bansal Carpets has been creating hand-knotted carpets, rugs, and floor coverings since 1979. We began in Agra and currently sell in 45 countries.",
  productSectionTitle: "Our Products",
  brandsTitle: "Brands we work with",
  certificationsTitle: "Compliances and Certifications",
  buttonContent: "Send an enquiry",
  logo: Logo,
  background: Background,
  foreground: Hero_Foreground,
  about: {
    heading: "About us",
    mainText: `We began our journey in Agra in 1979 with two people and two looms. Currently, we support around 500 families. We are one of the world's most trusted carpet dealers because of our customers and workers. 

      Bansal Rugs Pvt. Ltd.,  our sibling company, is also growing with the same customer-centric ideology. Many nations purchase Indian carpets and hand-knotted rugs from us, but we majorly export to USA.
      `,
    aboutBack: About_1_Background,
    aboutFore: About_1_Foreground,
  },
  description: {
    heading: "Vision",
    mainText: `At Bansal Carpets, we regard carpet production as a way to make a difference in the lives of both our customers and workers.

    Every step of the way is geared toward making our clients happy. We don't hesitate to go the additional mile to satisfy our clients. Whether you need a custom rug or any Indian rug, our team has the skills, creativity, and attention to detail to complete any requirements.`,
    descBack: Description_1_Background,
    descFore: Description_1_Foreground,
  },
  artisans: {
    heading: "Artisans",
    mainText:
      "Our artisans are at the heart of the work we do. Hand-drawn patterns are woven into long-lasting handcrafted carpets. Instead of machines, there are looms that make their work more remarkable than a machine. Our artisans work hard to create beautiful products to achieve total client satisfaction, we closely monitor every production step.",
    foregroundImage: Artisan_Foreground,
    backgroundImage: Artisan_Background,
  },
  sustainability: {
    heading: "Sustainability",
    mainText: `We are committed to creating eco-friendly carpets and floor coverings because we believe our future is in our hands. We're doing our part to lower our carbon footprint and protect the world using sustainable sourcing. Our carpets are created for long-lasting elegance and excellent quality. This exquisite artistry generates original rugs and carpets that will last generations.`,
    sustainabilityFore: Sustainability_1_Foreground,
    sustainabilityBack: Sustainability_1_Background,
  },

  prodLinkArray: [
    {
      title: "Rugs",
      link: "/#",
    },
    {
      title: "Pillows",
      link: "",
    },
    {
      title: "Poufs",
      link: "",
    },
    {
      title: "Wall hangers",
      link: "/#",
    },
  ],

  brandArray: [
    {
      title: "Company 1",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 2",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 3",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 4",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 5",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 6",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 7",
      link: "/#",
      image: Background,
    },
    {
      title: "Company 8",
      link: "/#",
      image: Background,
    },
  ],
  prodArray: [
    { title: "Saffire Collection", image: Product_1, link: "" },
    {
      title: "Pearl Collection",
      image: Product_2,
      link: "",
    },
    {
      title: "Courtyard Collection",
      image: Product_3,
      link: "",
    },
    {
      title: "Ganga Collection",
      image: Product_4,
      link: "",
    },
    // {
    //   title: "Product 5",
    //   image: Product_5,
    //   link: "",
    // },
    // {
    //   title: "Product 6",
    //   image: Product_6,
    //   link: "",
    // },
    // {
    //   title: "Saffire",
    //   image: Product_7,
    //   link: "",
    // },
    // {
    //   title: "Pearl",
    //   image: Product_8,
    //   link: "",
    // },
    // {
    //   title: "Mamluks",
    //   image: Product_9,
    //   link: "",
    // },
    // {
    //   title: "Cyprus",
    //   image: Product_10,
    //   link: "",
    // },
  ],
  imgArray: [
    {
      image: Certification_1,
    },
    {
      image: Certification_2,
    },
    {
      image: Certification_3,
    },
    // {
    //   image: Certification_4,
    // },
    // {
    //   image: Certification_5,
    // },
    // {
    //   image: Certification_6,
    // },
  ],

  aboutData: [
    {
      image: About_1,
    },
    // {
    //   image: About_2,
    // },
    // {
    //   image: About_3,
    // },
  ],

  sustainabilityData: {
    title: "Sustainability is the way of life, the way of our manufacturing",
    text: "",
    image: sustainability,
  },

  artisansData: {
    title:
      "The artisans are the masters behind creating our world class products",
    text: "",
    image: artisans,
  },

  emails: ["bansalcarpets@gmail.com"],
  
};

export default data;
