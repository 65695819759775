import * as S from "./Hero.style.js";
// import "./index.css";

import Animation from "react-animate-on-scroll";

const Hero = ({ background, mainText, subText, foreground }) => {
  return (
    <S.Container
      // type={type}
      style={{
        backgroundImage: 'url("' + background + '")',
      }}
      id="home"
    >
      <S.Box>
        <Animation animateIn="animate__fadeInLeft" animateOnce={true}>
          <S.Content>
            <S.Header className="heading">{mainText}</S.Header>
            <S.Para>{subText}</S.Para>
            <S.Button href="/#products">View products</S.Button>
          </S.Content>
        </Animation>
        {/* <Animation animateIn="animate__fadeInRight" animateOnce={true}>
          <S.Image src={foreground} />
        </Animation> */}
      </S.Box>
      {/* </Animation> */}
    </S.Container>
  );
};

export default Hero;
