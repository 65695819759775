import { styled } from "./../../styles/theme.config.js";

export const Container = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  width: "90vw",
  zIndex: -1,
  padding: "3em 0",
  background: "#F5F5F5",
  margin: "6em auto",
  maxWidth: "1440px",
  "@media only screen and (max-width : 600px)": {
    paddingBottom: "2em !important",
    marginBottom: "0",
  },
});

export const Header = styled("h2", {
  fontFamily: "Raleway, sans-serif",
  textAlign: "center",
  "@media only screen and (max-width : 600px)": {
    fontSize: "$3",
  },
});

export const Content = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  width: "80vw",
  margin: "3em 0",
  flexDirection: "row",
  "@media only screen and (max-width : 600px)": {},
});

export const Product = styled("a", {
  display: "flex",
  width: "20vw",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "3em",
  textDecoration: "none",
  maxWidth: "350px",
  "@media only screen and (max-width : 600px)": {
    width: "40vw",
  },
});

export const Image = styled("img", {
  width: "90%",
  margin: "0 1em",
  "@media only screen and (max-width : 600px)": {
    width: "90%",
  },
});

export const Title = styled("h3", {
  fontFamily: "Raleway, sans-serif",
  margin: "0.6em",
  textAlign: "center",
  color: "$black",
  marginTop: "3em",
});

export const Link = styled("a", {
  textDecoration: "none",
  color: "$primary",
});

export const Button = styled("a", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(29, 38, 36, 1)",
  borderRadius: "40px",
  fontSize: "$2",
  fontWeight: "600",
  height: "3em",
  padding: "0.5em 5em",
  margin: "1em",
  border: "none",
  color: "white",
  textDecoration: "none",
  "&:hover": {
    color: "$white",
  },
  "@media only screen and (max-width : 600px)": {
    fontSize: "$1",
    padding: "0.5em 2em",
  },
});
